/**
 * @file The Modal component (popover) for Datagee's main website.
 * @author Andrew Berger
 */

import React, {
    createContext 
} from "react"

import {
    Modal 
} from "react-bootstrap"

const ModalContext = createContext(() => console.error("Modal not setup correctly!",),)

/**
 * @param props
 */
function MainModal(props) {
    const {
        open,
        setOpened,
        args: {
            title,
            content,
            footer,
        },
    } = props

    return (
        <Modal
            size="lg"
            show={open}
            onHide={() => setOpened(false,)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            {content
                && (
                    <Modal.Body>
                        {content}
                    </Modal.Body>
                )}
            {footer
                && (
                    <Modal.Footer>
                        {footer}
                    </Modal.Footer>
                )}
        </Modal>
    )
}

export {
    ModalContext, MainModal 
}
