/**
 * @file The HomeScreen component for Datagee's main website.
 * @author Andrew Berger
 */

import React, {
    useState, 
} from "react"

import {
    Image,
    Col,
    Container,
    Stack,
    Button,
} from "react-bootstrap"

import ActionBanner from "./Components/ActionBanner"

import {
    useMediaQuery
} from "react-responsive"

import DGViewContainer from "./Components/DGViewContainer"
import Banner from "./Components/Banner"
import EstimatePanel from "./Components/Actions/QuickEstimate"
import QuoteContact from "./Components/Actions/QuoteContact"
import Consultation from "./Components/Actions/Consultation"
import PortfolioSlideshow from "./Components/Actions/PortfolioSlideshow"
import {
    ModalContext, 
} from "./Components/ModalPopup"
import ComingSoon from "./Components/ComingSoon"
import BackgroundImage from "../assets/background_image.png"
import CogentLogo from "../assets/CogentLogo.png"
import UTAgLogo from "../assets/UTAgLogo.png"
import PersonIcon from "../assets/personIcon.svg"
import DGInfo from "./Components/DGInfo"
import {
    BsArrowsAngleContract
} from "react-icons/bs"

/**
 * @param root0
 * @param root0.title
 * @param root0.icons
 * @param root0.height
 * @param root0.marginTop
 */
function ImageBanner({
    title, icons, height, marginTop = 0, 
},) {
    const tagCols = 6
    return (
        <Banner
            height={height}
            style={{
                marginTop, backgroundColor: "#1f1f1f", padding: 10
            }}
        >
            <Col sm={tagCols}
                className="d-flex align-items-center justify-content-center"
                style={{
                    textAlign: "center",
                }}
            >
                <span style={{
                    color: "#8c8c8c",
                    fontSize: "20px",
                }}
                >
                    { title }
                </span>
            </Col>
            { icons.map(({
                image, url, ratio, nudge = 0, 
            }, i) => (
                <Col key={url} sm={3}
                    className="d-flex align-items-center justify-content-center"
                    style={{ textAlign: "center", padding: 5 }}
                >
                    <Image
                        src={image}
                        style={{
                            maxWidth: 130,
                            height: "auto",
                            cursor: "pointer",
                            textAlign: "center"
                        }}
                        onClick={() => window.open(url, "_blank",)}
                    />
                </Col>
            ),)}
        </Banner>
    )
}



/**
 * @param root0
 * @param root0.marginTop
 */
function MemberPortal({
},) {
    return (
        <div style={{
            textAlign: "right",
            paddingRight: "8%",
            paddingBottom: 20
        }}>
            <a
                href="https://portal.datagee.com"
                style={{
                    color: "#fff", textDecoration: "none", 
                }}
            >
                Member Portal
                <Image
                    src={PersonIcon}
                    className="ms-3"
                    style={{
                        width: "3em", height: "3em", 
                    }}
                />
            </a>
        </div>
    )
}

/**
 * @param props
 */
function HomeScreen(props,) {
    const {
        navigation,
    } = props

    const [openedContent, setOpened] = useState(null)
    const [estimateData, setEstimateData] = useState({})
    const [fullscreen, setFullscreen] = useState(false)

    const isSmallScreen = useMediaQuery({
        query: "(max-width: 575px)"
    })

    const isShortScreen = useMediaQuery({
        query: "(max-height: 675px)"
    })

    const partnerIcons = [
        {
            image: CogentLogo, url: "https://www.cogentsecurity.com/", ratio: 282 / 62, 
        },
        {
            image: UTAgLogo, url: "https://utextension.tennessee.edu/", ratio: 368 / 66, nudge: 1.1, 
        },
    ]

    const actionLinks = [
        {
            name: "Calculator",
            component: <EstimatePanel setEstimateData={setEstimateData} />,
        },
        {
            name: "Get a Quote", component: <QuoteContact estimateData={estimateData} />, 
        },
        {
            name: "Consultation", component: <Consultation />, 
        },
    ]

    const actionDescription = (
        "Our team of engineering experts has over 15 combined years of experience. From creating detailed mockups to launching your application, let Datagee bring your mobile or web application to life."
    )
    
    return (
        <DGViewContainer
            backgroundImage={BackgroundImage}
            navigation={navigation}
            padAboveContent
        >
            {fullscreen ? (
                <div style={{ width: "100vw", height: "100vh", backgroundColor: "white", padding: 25 }}>
                    <div 
                        style={{ textAlign: "right", width: "100%" }} 
                        onClick={() => setFullscreen(!fullscreen)}
                        title="Exit fullscreen"
                    >
                        <BsArrowsAngleContract />
                    </div>
                    {openedContent}
                </div>
            ) : (
                <>
                    <div>
                        {(!isShortScreen) && <MemberPortal/>}
                        <div style={{
                            overflow: "hidden",
                        }}>
                            {(!isSmallScreen && !isShortScreen) ? <ActionBanner
                                title="Data Generation and Engineering Experts"
                                description={actionDescription}
                                links={actionLinks}
                                height={fullscreen ? 100 : 30}
                                openedContent={openedContent}
                                setOpened={setOpened}
                                fullscreen={fullscreen}
                                setFullscreen={setFullscreen}
                            /> : <Stack className="d-flex justify-content-center align-items-center" style={{ textAlign: "center" }}>
                                {actionLinks.map(a => {
                                    return (
                                        <ModalContext.Consumer key={a.name}>
                                            {
                                                (openModal) => (
                                                    <Col className="d-flex justify-content-center" sm={4}>
                                                        <Button 
                                                            style={
                                                                { 
                                                                    color: "white", 
                                                                    height: 50,
                                                                    width: "80vw",
                                                                    borderColor: "white",
                                                                    margin: 10,
                                                                    marginBottom: isShortScreen ? 3 : 10
                                                                }
                                                            }
                                                            onClick={()=>{openModal({
                                                                title: a.name,
                                                                content: a.component,
                                                            })}}
                                                            variant="outline-danger"
                                                        >
                                                            {a.name}
                                                        </Button>
                                                    </Col>
                                                )
                                            }
                                        </ModalContext.Consumer>
                                    )
                                })}</Stack>}
                        </div>
                        {(!isShortScreen) && (
                            <div style={{ overflowX: "hidden" }}>
                                <ImageBanner
                                    title="Trusted software engineering and cybersecurity testing"
                                    icons={partnerIcons}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </DGViewContainer>
    )
}

export default HomeScreen
