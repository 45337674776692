import React from "react"
import DGLogo from "../../assets/dgClear64.png"

const DGLetterhead = (props) => {
    const {
        children,
        style,
    } = props

    return (
        <div id="policy" style={{ ...style }}>
            <img id="letterheadLogo" src={DGLogo} />
            <span id="letterheadReserved">&reg;</span>
            {children}
        </div>
    )
}

export default DGLetterhead