import React from "react"
import DGViewContainer from "./Components/DGViewContainer"
import DGInfo from "./Components/DGInfo"
import BackgroundImage from "../assets/background_image.png"
import DGInfoPage from "./Components/DGInfoPage"
import DGHR from "./Components/DGHR"

let Services = (props) => {
    const {
        navigation
    } = props
    return (
        <DGViewContainer 
            navigation={
                navigation
            }
            backgroundImage={BackgroundImage}
            selectedLink={0}
        >

            <DGInfoPage>
                <DGInfo headingSize="h1">
                    Services
                    <DGHR />
                </DGInfo>
                <DGInfo headingSize="h4">
                    Datagee offers the following services at this time:
                </DGInfo>
                <DGInfo>
                    <ul>
                        <li>React.js Application Development for Web</li>
                        <li>React Native Application Development for Android and iPhone</li>
                        <li>Swift Development for Native iPhone Applications</li>
                        <li>Python Programming for iOT Devices and Systems</li>
                        <li>Cybersecurity Testing for Web or Cloud Endpoints (Ethical Hacking)</li>
                        <li>C/C++ Software Development for Systems</li>
                        <li>Application and Website Design Mockups</li>
                    </ul>
                </DGInfo>
            </DGInfoPage>
        </DGViewContainer>
    )
}

export default Services