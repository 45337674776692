import React from "react"
import {
    Button,
} from "react-bootstrap"

export const dgLinkBlue = "rgb(0,200,255)"

const DGLink = (props) => {
    const {
        href,
        children,
        color,
        alignCenter
    } = props
    
    const DEFAULT_TEXT_COLOR = dgLinkBlue

    return (
        <span 
            style={{ 
                textAlign: alignCenter ? "center" : "left",
            }}
        >
            <u>
                <a 
                    href={href}
                    style={{ 
                        textDecoration: "none", 
                        color: color ?? DEFAULT_TEXT_COLOR,
                    }}
                >
                    { children }
                </a>
            </u>
        </span>
    )
}

export default DGLink