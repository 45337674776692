import React from "react"

let ComingSoon = () => {
    return (
        <div>
            This feature is coming soon! Thank you for your patience while we take our website into beta.
        </div>
    )
}

export default ComingSoon