import FirebaseTools from "../Model/FirebaseTools"

class AnnouncementsViewModel {
    // Class Constants
    COLLECTION_NAME = "Announcements"
    DOC_ID = "ml5TS64wtlFftxs2eapy"

    constructor() {
        this.dbtools = new FirebaseTools()
    }

    listAnnouncements = async (page) => {
        return await this.dbtools.getFirebaseData(page, this.COLLECTION_NAME, this.DOC_ID)
    }
}

export default AnnouncementsViewModel