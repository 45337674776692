import React, { useState } from "react"
import Banner from "./Banner"
import DGInfo from "./DGInfo"
import DGHR from "./DGHR"
import { BsArrowsFullscreen } from "react-icons/bs"
import {
    CloseButton,
    Col,
    Container,
    Row
} from "react-bootstrap"

let selectedActionItem = -1

/**
 * @param props
 */
function ActionBanner(props) {
    const {
        title,
        description,
        links,
        height,
        small,
        setOpened,
        openedContent,
        fullscreen,
        setFullscreen,
    } = props

    const actionComponent = openedContent && (
        <ActionComponent 
            name={links[selectedActionItem] && links[selectedActionItem].name} 
            exit={() => {setOpened(null); selectedActionItem = -1}}
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            hasFullscreenOption={links[selectedActionItem] && links[selectedActionItem].fullscreenOption}
        >
            <span>{ openedContent }</span>
        </ActionComponent>
    )

    const transitionDuration = "0.75s"
    return (
        <Banner
            height={height}
            style={{
                backgroundColor: "#fff",
                width: "174vw",
                transform: (actionComponent) ? "translateX(-72.5vw)" : "translateX(0%)",
                transition: "transform " + transitionDuration,
            }}
        >
            <Col
                style={{ width: "100vw" }}
            >
                <Container style={{ height: "100%" }} className="d-flex align-items-center">
                    <Col>
                        <DGInfo 
                            headingSize="h3" 
                            color="#555"
                        >
                            { title }
                        </DGInfo>
                        <p
                            style={{
                                color: "#8c8c8c",
                                fontSize: "18px",
                            }}
                        >
                            { description }
                        </p>
                    </Col>
                </Container>
            </Col>
            <Col
                sm={2}
                className="border-start border-end pe-0 ps-0"
                style={{
                    borderLeftColor: "#ddd",
                    borderLeftWidth: "2px",
                    height: (small) ? "auto" : "100%",
                }}
            >
                {links.map(({
                    component, name, 
                }, i) => (
                    <Container
                        key={name}
                        className={
                            "d-flex border-top"
                            + " align-items-center justify-content-center"
                        }
                        style={{
                            borderTopColor: "#ddd",
                            borderTopWidth: (i === 0) ? "0px" : "2px",
                            height: `${100 / links.length}%`,
                            cursor: "pointer",
                            backgroundColor: (i === selectedActionItem) ? "rgba(100, 100, 100, 0.15)" : "white"
                        }}
                        onClick={(e) => {
                            setOpened(component)
                            selectedActionItem = i
                        }}
                    >
                        <span
                            style={{
                                color: "#2bcfe2",
                                fontSize: "20px",
                            }}
                        >
                            { name }
                        </span>
                    </Container>
                ),)}
            </Col>
            <Col
                style={{
                    overflowY: "auto",
                    height: "100%",
                }}
            >
                { actionComponent }
            </Col>
        </Banner>
    )
}

/**
 * @param props
 */
function ActionComponent(props) {
    const {
        children, exit, name, fullscreen, setFullscreen, hasFullscreenOption,
    } = props
    return (
        <Container>
            <Row style={{ paddingTop: 10 }}>
                <DGInfo style={{ width: "30%" }} headingSize="h3">
                    {name && name}
                </DGInfo>
                <span style={{ width: "20%" }}>
                    {
                        hasFullscreenOption && (
                            <BsArrowsFullscreen 
                                onClick={
                                    () => setFullscreen(!fullscreen)
                                } 
                                title="Pop out into fullscreen"
                            />
                        )
                    }
                </span>
                <span style={{ width: "50%", alignItems: "center", paddingTop: 3, textAlign: "right", }}>
                    <CloseButton 
                        onClick={exit}
                    />
                </span>
                <DGHR />
            </Row>
            { children }
        </Container>
    )
}

export default ActionBanner