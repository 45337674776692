/**
 * @file The View component for each page of Datagee's main website.
 * @author Andrew Berger
 */

import React, {
} from "react"

import DGNavBar from "./DGNavBar"

/**
 * @param props
 */
function DGView(props) {
    const {
        children,
        navColor,
        navigation: {
            getPathFromName, routes: {
                headerLinks 
            }, 
        },
        selectedLink,
        padAboveContent,
    } = props

    let height = "50vh"

    return (
        <div style={{
            height: "100vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column"
        }}>
            <DGNavBar 
                getPathFromName = { getPathFromName }
                headerLinks = { headerLinks }
                selectedLink = { selectedLink }
                color = { navColor }
            />
            {padAboveContent && (
                <div
                    className="pushContent"
                    style={{
                        height: height,
                        overflow: "hidden"
                    }}
                >
                </div>)
            }
            <span>
                { children }
            </span>
            <div style={{
                flex: 1
            }}
            >
            </div>
            <footer
                className="d-flex align-items-center justify-content-center"
                style={{
                    height: 50,
                    textAlign: "center",
                    color: "white",
                    fontSize: 13,
                    flexShrink: 0
                }}
            >
                &copy; 2021-{new Date().getFullYear()}. Datagee &reg;, LLC. All rights reserved.
            </footer>
        </div>
    )
}

export default DGView