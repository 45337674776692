import React, { useState, useEffect } from "react"
import DGInfo from "./DGInfo"
import HoursViewModel from "../../ViewModel/HoursVM"
import LoadingIndicator from "./LoadingIndicator"

let Hours = () => {

    const [hours, setHours] = useState({})
    const [HoursVM, _] = useState(new HoursViewModel())

    let loadHours = () => {
        const location = "Location"
        HoursVM && HoursVM.listHours(location).then(
            (hours) => {
                setHours(hours)
            }
        )
    }

    useEffect(loadHours, [])

    return (
        <DGInfo>
            {Object.keys(hours).length === 0 && <LoadingIndicator />}
            {Object.keys(hours).length > 0 && HoursVM.DAYS_IN_ORDER.map(
                day => {
                    const openHours = HoursVM.prettyPrintHours(hours[day])
                    return (
                        <span key={day}>{day}: {openHours}<br /></span>
                    )
                }
            )}
        </DGInfo>
    )
}

export default Hours