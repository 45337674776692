/**
 * @file The ViewContainer component for each View of Datagee's main website.
 * @author Andrew Berger
 */

import React, {
    useState 
} from "react"

import {
    MainModal, ModalContext 
} from "./ModalPopup"

import {
    Container,
} from "react-bootstrap"

import DGView from "./DGView"

/**
 * @param props
 */
function DGViewContainer(props) {
    const {
        backgroundImage,
        children,
        navColor,
        navigation,
        selectedLink,
        style,
        padAboveContent,
    } = props

    const [modalOpen, setModalOpen] = useState(null)
    const [modalArgs, setModalArgs] = useState({
        title: "undefined", content: "undefined" 
    })
    const openModal = (args) => {
        setModalOpen(true)
        setModalArgs(args)
    }

    if (backgroundImage) {
        return (
            <div
                style={{
                    ...style,
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto"
                }}
            >
                <MainModal
                    open={modalOpen}
                    setOpened={setModalOpen}
                    args={modalArgs}
                />
                <ModalContext.Provider value={openModal} style={{ height: "100%" }}>
                    <DGView 
                        children={children}
                        navColor={navColor}
                        navigation={navigation}
                        selectedLink={selectedLink}
                        padAboveContent={padAboveContent}
                        clear
                    />
                </ModalContext.Provider>
            </div>
        )
    }

    return (
        <div style={{
            backgroundColor: "black",
            height: "100vh",
            width: "100vw"
        }}>
            <DGView 
                children={children}
                navigation={navigation}
                selectedLink={selectedLink}
                navColor="black" 
            />
        </div>
    )
}

export default DGViewContainer
