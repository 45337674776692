/**
 * @file The QuantitySelector component used in various forms across the site.
 * @author Andrew Berger
 */

import React, {
    useState 
} from "react"

import {
    FaPlus,
    FaMinus,
} from "react-icons/fa"

import {
    Container,
    Button,
} from "react-bootstrap"

/**
 * @param props
 */
export default function QuantitySelector(props) {
    const {
        quantity, setQuantity, upperRange, style,
    } = props

    const [shouldDisableDecrementButton, setDisableDecrementButton] = useState(true)
    const decrementQuantity = () => {
        const q = parseInt(quantity)
        onValueChange((q - 1))
    }

    const incrementQuantity = () => {
        const q = parseInt(quantity)
        onValueChange((q + 1))
    }

    const onValueChange = (value) => {
        if (!isNaN(value) && value !== "") {
            const q = parseInt(value)
            setDisableDecrementButton(quantity < 1)
            if (q < 0 || (upperRange && q > upperRange)) {
                alert(`Invalid quantity. Please enter a number between 0 and ${upperRange}`)
                return
            }
            setDisableDecrementButton(q < 1)
            setQuantity(q)
        }
    }
    return (
        <Container style={{
            ...style, flexDirection: "row", font: 12, padding: 0
        }}>
            <FaMinus
                onClick={decrementQuantity}
                color={(quantity === 0) ? "#aaa" : "#000"}
            />
            <input
                onChange={(e) => onValueChange(e.target.value)}
                value={quantity.toString()}
                style={{
                    width: 30, textAlign: "center", border: "none" 
                }}
            />
            <FaPlus
                onClick={incrementQuantity}
                color={(quantity == upperRange) ? "#aaa" : "#000"}
            />
        </Container>
    )
}
