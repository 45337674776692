import React, {
    useState,
} from "react"

import {
    Button,
    Card,
    Col,
    Row,
    Form
} from "react-bootstrap"

import PermissionsButton from "../PermissionsButton"

import {
    sendQuoteRequest
} from "./QuoteContact"

import {
    FaPen,
} from "react-icons/fa"

import {
    MAX_VIEWS_OR_PAGES,
    toRangeName,
    canEstimateAutomatically,
    calculateEstimate,
    options,
    requiredPermissions,
} from "../../../ViewModel/SoftwareEstimatesVM"

import QuantitySelector from "../QuantitySelector"

import DGInfo from "../DGInfo"
import DGLink, { dgLinkBlue } from "../DGLink"

const initialEstimateOptions = {
    isNewProject: true,
    projectType: undefined,
    loginRequired: false,
    viewsOrPagesRequired: 0,
    permissionsRequired: {},
    dailyActiveUsers: 0,
    userScale: undefined,
}

const EstimatePanel = () => {
    const [canCalculateEstimate, setCanCalculateEstimate] = useState(true)
    const [estimate, setEstimate] = useState([])
    const [estimateOptions, setEstimateOptions] = useState(initialEstimateOptions)
    const [showPermissions, setShowPermissions] = useState(false)
    const [definition, setDefinition] = useState("")
    const [permissions, setPermissionsObject] = useState(Object.fromEntries(
        options.phoneServices.map((name) => [name, false])
    ))
    const setPermissions = (newPerm) => {
        const permissionsObject = { ...permissions, ...newPerm }
        setPermissionsObject(permissionsObject)
        changeEstimateOption("permissionsRequired", permissionsObject)
    }

    const changeEstimateOption = (option, newValue) => {
        let newEstimateOptions = { ...estimateOptions }
        newEstimateOptions[option] = newValue
        setEstimateOptions(newEstimateOptions)
        setCanCalculateEstimate(canEstimateAutomatically(newEstimateOptions))
        calculateNewEstimate(newEstimateOptions)
    }

    const calculateNewEstimate = (options) => {
        const estimateCalculation = calculateEstimate(options)
        if (estimateCalculation) {
            setEstimate(estimateCalculation) 
        } else {
            setEstimate([])
        }
    }
    
    const GlossWord = ({
        children, def 
    }) => (
        <>
            <span
                style={{
                    cursor: "help", color: dgLinkBlue 
                }}
                onClick={ 
                    () => {
                        setDefinition(definition === "" ? def : "")
                    }
                }
                title={def}
            >
                {children}
            </span>
            {def === definition && <p style={{
                position: "absolute",
                zIndex: "20000",
                width: "60vw",
                backgroundColor: "white",
                textAlign: "center",
                border: "1px solid rgba(10,10,10,0.2)",
                boxShadow: "1px 2px 2px rgba(10,10,10,0.1)",
                whiteSpace: "pre-line",
                padding: 15
            }}>{definition}</p>}
        </>
    )

    return (
        <div onClick={()=>{ definition !== "" && setDefinition("") }}>
            <DGInfo>
                At Datagee, we perform detailed software estimates for every project. The full estimation process can be time-consuming, and therefore expensive.
                While no details should be left out in a software estimate, it can sometimes be helpful for clients to see a ballpark range of their total project cost before proceeding with a full estimate.
            </DGInfo>
            <br />
            <DGInfo>
                If you require work to be performed on an existing project, or for any project that is not a new website or mobile application, please <DGLink href="contact">contact us</DGLink>.
                The figures calculated by this tool are only for informational purposes, and do not give any guarantee as to what the project will actually cost once all project details have been taken into consideration.
            </DGInfo>
            <br />
            <DGInfo>
                Before entering the project details below, try to imagine what your app or website will look like. 
                It is helpful to <GlossWord def="A wireframe is a basic sketch of an app or website">wireframe</GlossWord> the project before continuing 
                to give you good idea of how many pages or views the project requires. These pages or views can have <GlossWord def="Dynamic content is page or view content that changes conditionally">dynamic content</GlossWord>.
                If you have any questions, please don't hesitate to <DGLink href="contact">contact us</DGLink>.
            </DGInfo>
            <br />
            <Row>
                <Col>
                    <EstimateQuestionRow 
                        responseUIElement={
                            <Form.Check 
                                label={estimateOptions.isNewProject ? "Yes" : "No"}
                                onClick={()=>{ changeEstimateOption("isNewProject", !estimateOptions.isNewProject) }}
                                defaultChecked={estimateOptions.isNewProject}
                            />
                        }
                    >
                        Is this a new software project?
                    </EstimateQuestionRow>
                    {estimateOptions.isNewProject && 
                        <>
                            <EstimateQuestionRow 
                                responseUIElement={
                                    <Form.Select
                                        onChange={
                                            (e) => {
                                                changeEstimateOption("projectType", e.target.value)
                                            }
                                        }
                                        value={estimateOptions.projectType}
                                        size="sm"
                                    >
                                        {!estimateOptions.projectType && <option value="">Type</option>}
                                        {
                                            options.projectTypes.map(
                                                type => {
                                                    return <option key={type} value={type}>{type}</option>
                                                }
                                            )
                                        }
                                    </Form.Select>
                                }
                            >
                                What type of project is this?
                            </EstimateQuestionRow>
                            {
                                estimateOptions.projectType && canEstimateAutomatically(estimateOptions) && 
                                <>
                                    <EstimateQuestionRow 
                                        responseUIElement={
                                            <Form.Check
                                                label={estimateOptions.loginRequired ? "Yes" : "No"}
                                                onClick={()=>{ changeEstimateOption("loginRequired", !estimateOptions.loginRequired) }}
                                            />
                                        }
                                    >
                                        {`Does the ${estimateOptions.projectType.toLowerCase()} require login?`}
                                    </EstimateQuestionRow>       
                                    <EstimateQuestionRow
                                        responseUIElement={
                                            <QuantitySelector
                                                upperRange={MAX_VIEWS_OR_PAGES}
                                                quantity={estimateOptions.viewsOrPagesRequired}
                                                setQuantity={(val) => changeEstimateOption("viewsOrPagesRequired", val)}
                                                size="sm"
                                            />
                                        }
                                    >
                                        How many&nbsp;
                                        {estimateOptions.projectType === "App" &&
                                            <GlossWord 
                                                def="A &quot;view&quot; is everything you see on a mobile application screen at one time. One example is a Login view."
                                            >
                                                views
                                            </GlossWord>
                                        }
                                        {estimateOptions.projectType === "Website" &&
                                            <span>pages</span>
                                        }
                                        <span> will the {estimateOptions.projectType.toLowerCase()} have?</span>
                                    </EstimateQuestionRow>
                                    {estimateOptions.projectType === "Website" ? (
                                        <EstimateQuestionRow
                                            responseUIElement={
                                                <Form.Select
                                                    onChange={({
                                                        target: {
                                                            value 
                                                        } 
                                                    }) => changeEstimateOption("userScale", value)}
                                                    value={estimateOptions.userScale}
                                                    size="sm"
                                                >
                                                    {!estimateOptions.userScale && <option value="">Select an Option</option>}
                                                    {options.userScales.map(([low, high], i) => (
                                                        <option key={low} value={i + 1}>
                                                            {low}
                                                            {high !== "+" ? "-" : ""}
                                                            {high}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            }
                                        >
                                            How many users each day?
                                        </EstimateQuestionRow>
                                    ) : (
                                        <EstimateQuestionRow
                                            responseUIElement={
                                                <div>
                                                    <Button
                                                        size="sm"
                                                        onClick={() => {
                                                            setShowPermissions(!showPermissions)
                                                        }}
                                                    >
                                                        <span>
                                                            <FaPen />
                                                            &nbsp;
                                                            { options.phoneServices.filter((name) => permissions[name]).length }
                                                        </span>
                                                    </Button>
                                                </div>
                                            }
                                        >
                                            Select&nbsp;
                                            <GlossWord
                                                def="Services are elements of an application that access the device's peripherals"
                                            >
                                                services
                                            </GlossWord> 
                                            &nbsp;the app needs to access.
                                        </EstimateQuestionRow>
                                    )}
                                    {showPermissions && estimateOptions.projectType === "App" && (
                                        <PermissionsSelectionUI
                                            permissions={permissions}
                                            setPermissions={setPermissions}
                                        />
                                    )}
                                </>
                            }
                        </>
                    }
                </Col>
                <Col lg={3} md={12} sm={12} xs={12} className="align-items-center">
                    <Card style={{ margin: "10px 0px" }}>
                        <Card.Body style={{ textAlign: "center" }}>
                            <Card.Title>Your Quick Estimate</Card.Title>
                            <Card.Text>
                                {canCalculateEstimate && estimate.length > 0 &&
                                    <span>Your {estimateOptions.projectType.toLowerCase()} beta is in the {toRangeName(estimate)} range (USD)
                                        <br />
                                        <span  
                                            style={{ color: dgLinkBlue }}
                                            onClick={()=>{
                                                let sendingEstimateOptions ={
                                                    projectType: estimateOptions.projectType,
                                                    quickEstimate: estimate.join("-") + " USD",
                                                    name: "",
                                                    projectName: "Project",
                                                    description: "<Briefly Describe Project Here>",
                                                    viewsOrPagesRequired: estimateOptions.viewsOrPagesRequired,
                                                    loginRequired: estimateOptions.loginRequired,
                                                }
                                                if (estimateOptions.projectType === "App") {
                                                    const reqPerm = requiredPermissions(estimateOptions.permissionsRequired)
                                                    if (reqPerm.length > 0)
                                                        sendingEstimateOptions.permissionsRequired = reqPerm.join()
                                                } else if (estimateOptions.projectType === "Website") {
                                                    sendingEstimateOptions.dailyActiveUsers = options.userScales[estimateOptions.userScale - 1].join("-")
                                                }
                                                sendQuoteRequest(sendingEstimateOptions)
                                            }}
                                        >
                                            Get a full estimate.
                                        </span>
                                    </span>
                                }
                                {canCalculateEstimate && estimate.length === 0 &&
                                    "Your quick estimate still needs more details"
                                }
                                {!canCalculateEstimate &&
                                    <div>
                                        The quick estimate calculator does not support this project type.
                                        <br />
                                        <DGLink href="contact">Contact us</DGLink> to schedule a consultation today!
                                    </div>
                                }
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const PermissionsSelectionUI = ({
    permissions, setPermissions 
}) => {
    return (
        <div>
            {Object.entries(permissions).map(([name, selected]) => {
                return (
                    <PermissionsButton
                        selected={selected}
                        key={name}
                        style={{
                            color: "#0d6efd",
                            borderColor: "#0d6efd"
                        }}
                        onClick={() => {
                            const newPermissions = permissions
                            newPermissions[name] = !newPermissions[name]
                            setPermissions(newPermissions)
                        }}
                    >
                        {name}
                    </PermissionsButton>
                )
            })}
        </div>
    )
}

const EstimateQuestionRow = (props) => {
    const {
        children,
        responseUIElement,
        style,
    } = props
    return (
        <>
            <Row style={{ ...style, height: 35, alignItems: "center" }}>
                <Col xs={9} sm={9} md={9} lg={8}>
                    <DGInfo
                        style={
                            { 
                                overflow:"hidden", 
                                textOverflow: "ellipsis", 
                                whiteSpace: "nowrap",
                            }
                        }
                    >
                        {children}
                    </DGInfo>
                </Col>
                <Col xs={3} sm={3} md={3} lg={4}>
                    {responseUIElement}
                </Col>
            </Row>
        </>
    )
}

export default EstimatePanel