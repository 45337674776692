/**
 * @file The Banner component displayed in large webpages on Datagee's main website.
 * @author Andrew Berger
 */

import React from "react"

import {
    Container,
    Row,
    Col,
} from "react-bootstrap"

/**
 * @param props
 */
function Banner(props) {
    const {
        children, height, style = {
        } 
    } = props

    return (
        <Row
            style={{
                ...style,
                height: `${height}vh`,
            }}
        >
            { children }
        </Row>
    )
}

export default Banner
