import FirebaseConfigAppAndAnalytics from "./FirebaseConfig"
import { getFirestore, doc, getDoc } from "firebase/firestore"

class FirebaseTools {
    constructor() {
        const [
            firebaseConfig,
            app,
            analytics
        ] = FirebaseConfigAppAndAnalytics
        this.data = undefined
        this.firebaseConfig = firebaseConfig
        this.firebaseApp = app
        this.firebaseAnalytics = analytics
        // Initialize the firestore with the firebase configuration
        this.firestore = getFirestore(this.firebaseApp)
    }
    
    getFirebaseData = async (field, collection, documentID) => {
        const docRef = doc(this.firestore, collection, documentID)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            this.data = docSnap.data()[field]
        } else {
            // doc.data() will be undefined in this case
            throw new Error(`The ${collection} data could not be loaded from firestore.`)
        }
        return this.data
    }
}

export default FirebaseTools