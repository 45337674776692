/**
 * @file The Routes for all addressable paths on the site.
 * @author Andrew Berger
 */

import HomeScreen from "../View/HomeScreen"
import ConstructionScreen from "../View/ConstructionScreen"
import Services from "../View/Services"
import Contact from "../View/Contact"
import Privacy from "../View/Policies/Privacy"
import EULA from "../View/Policies/EULA"
import Cookies from "../View/Policies/Cookies"
import Announcements from "../View/Announcements"
const PORTAL_URL = "https://portal.datagee.com"

const Routes = {
    routes: {
        HomeScreen,
        Privacy,
        EULA,
        Cookies,
        Services,
        Announcements,
        Contact
    },
    paths: {
        HomeScreen: "/home",
        Privacy: "/privacy",
        EULA: "/eula",
        Services: "/services",
        Announcements: "/announcements",
        Contact: "/contact",
        Cookies: "/cookies",

    },
    headerLinks: [
        ["Services", "Services"],
        ["Announcements", "Announcements"],
        ["Contact", "Contact"],
        [PORTAL_URL, "Portal"],
    ],
    config: {
        initialRouteName: "HomeScreen"
    }
}

export default Routes
