import React from "react"
import {
    Container
} from "react-bootstrap"

const DGInfoPage = (props) => {
    const {
        children,
    } = props

    return (
        <Container
            style={{
                paddingTop: "4vh"
            }}
        >        
            <div style={{ backgroundColor: "rgba(255,255,255,1)", padding: "10px 50px", borderRadius: 9 }}>
                {children}
            </div>
        </Container>
    )

}

export default DGInfoPage