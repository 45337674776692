import React from "react"

let DGInfo = (props) => {
    const {
        headingSize,
        children,
        color,
        alignCenter,
        style,
        title,
    } = props
    
    const DEFAULT_TEXT_COLOR = "rgb(50,50,50)"

    const availableHeadingSizes = ["h1", "h2", "h3", "h4"]
    
    if (headingSize && !availableHeadingSizes.includes(headingSize)) 
        throw new Error(`Heading size not supported. Available sizes are: ${availableHeadingSizes.join()}`)
    
    return (
        <div 
            title={title ?? ""}
            className={headingSize ?? ""} 
            style={{ ...style, color: color ?? DEFAULT_TEXT_COLOR, textAlign: alignCenter ? "center" : "left" }}
        >
            {children}
        </div>
    )
}

export default DGInfo