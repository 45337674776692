import FirebaseTools from "../Model/FirebaseTools"

class HoursViewModel {
    // Class Constants
    COLLECTION_NAME = "Hours"
    DOC_ID = "eiLeawABn2V3u65TruHD"
    DAYS_IN_ORDER = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    constructor() {
        this.dbtools = new FirebaseTools()
    }

    listHours = async (location) => {
        return await this.dbtools.getFirebaseData(location, this.COLLECTION_NAME, this.DOC_ID)
    }

    prettyPrintHours = (hoursForDay) => {
        if (hoursForDay[0] === "Closed") {
            return "Closed"
        } else {
            const [openTime, closeTime] = hoursForDay
            return `${openTime}-${closeTime}`
        }
    }
}

export default HoursViewModel