// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyANrK2VpgVJMOsPu7pNWqYF6Wn3ZSjj-m4",
    authDomain: "api-project-450050998233.firebaseapp.com",
    databaseURL: "https://api-project-450050998233.firebaseio.com",
    projectId: "api-project-450050998233",
    storageBucket: "api-project-450050998233.appspot.com",
    messagingSenderId: "450050998233",
    appId: "1:450050998233:web:76fcbf8b1e691bbec7a3c8",
    measurementId: "G-FRVLX5HV9J"
}
  
// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const FirebaseConfigAppAndAnalytics = [
    firebaseConfig,
    app,
    analytics,
]

export default FirebaseConfigAppAndAnalytics