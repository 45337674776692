import React from "react"
import DGViewContainer from "./Components/DGViewContainer"
import DGInfo from "./Components/DGInfo"
import DGLink from "./Components/DGLink"
import BackgroundImage from "../assets/background_image.png"
import Hours from "./Components/Hours"
import DGInfoPage from "./Components/DGInfoPage"
import DGHR from "./Components/DGHR"

let Contact = (props) => {
    const {
        navigation
    } = props

    return (
        <DGViewContainer 
            navigation={navigation}
            backgroundImage={BackgroundImage}
            selectedLink={2}
        >
            <DGInfoPage>
                <DGInfo headingSize="h1">
                    Contact Us
                    <DGHR />
                </DGInfo>
                <DGInfo headingSize="h3">
                    Location
                </DGInfo>
                <DGInfo>
                    Datagee is located in the Sequoyah Hills community of Knoxville, Tennessee at:
                    <br />
                    
                    <DGLink
                        href="https://www.google.com/maps/place/702+Lindsay+Pl+SW,+Knoxville,+TN+37919/@35.9521713,-83.9503739,17z/data=!3m1!4b1!4m5!3m4!1s0x885c3d46a1072683:0xc7f201cff95c54fd!8m2!3d35.9521713!4d-83.9481852"
                    >
                        702 Lindsay Place
                        <br />
                        Knoxville, TN 37919
                    </DGLink>
                    <DGHR />
                </DGInfo>
                <DGInfo headingSize="h2">
                    Phone
                </DGInfo>
                <DGInfo>
                    <DGLink
                        href="tel:8653472525"
                    >
                        (865) 347-2525
                    </DGLink>
                    <DGHR />
                </DGInfo>
                <DGInfo headingSize="h2">
                    Email
                </DGInfo>
                <DGInfo>
                    <DGLink
                        href="mailto:estimates@datagee.com"
                    >
                        estimates@datagee.com
                    </DGLink>
                    <DGHR />
                </DGInfo>
                <DGInfo headingSize="h2">
                    Make an Appointment
                </DGInfo>
                <DGInfo>
                    <DGLink
                        href="https://www.picktime.com/datagee"
                    >
                        Book your free consultation today 
                    </DGLink>
                    &nbsp;(offer subject to change)
                    <DGHR />
                </DGInfo>
                <DGInfo headingSize="h2">
                    Hours This Week
                </DGInfo>
                <Hours />
                <br />
            </DGInfoPage>
        </DGViewContainer>
    )
}

export default Contact