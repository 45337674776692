/**
 * @file Navigation manages routing along with Routes.js.
 * @author Ben Croisdale
 * @see Routes
 */

import React from "react"

import {
    BrowserRouter,
    Routes,
    Route,
    useNavigate,
} from "react-router-dom"

import routes from "./Routes"

export default function NavigationRoot(props) {
    return (
        <BrowserRouter>
            <NavigationChild />
        </BrowserRouter>
    )
}

function NavigationChild(props) {
    const navigate = useNavigate()

    const getPathFromName = (name) => routes.paths[name] || `${name}`

    const eprops = {
        ...props,
        navigation: {
            routes,
            navigate,
            getPathFromName,
            navTo: (pageName) => navigate(getPathFromName(pageName))
        }
    }

    return (
        <Routes path="/">
            <Route
                index
                element={
                    React.createElement(
                        routes.routes[routes.config.initialRouteName],
                        eprops,
                        null
                    )
                }
            />
            {Object.entries(routes.routes).map(([name, element]) => (
                <Route
                    path={getPathFromName(name)}
                    key={name}
                    element={React.createElement(element, eprops, null)}
                />
            ))}
        </Routes>
    )
}
