const root5 = Math.sqrt(5)
const phi = (1 + root5) / 2 // The golden ratio (1 + sqrt(5)) / 2

/**
 * Explicit form of the Fibonacci sequence. Only correct up to F_55
 * @param n
 */
function fib(n) {
    if (0 < n && n < 51) {
        return Math.round(((phi)**n - (1-phi)**n) / root5)
    }
    throw "Can only calculate Fibonacci numbers from 1 to 51"
}

const estimateFactors = {
    testing: 1.35,
    login: 2,
    rate: 100,
    webpageCompletionTime: 10,
}

export const MAX_VIEWS_OR_PAGES = 30

export const options = {
    projectTypes: [
        "App",
        "Website",
        "iOT Device",
        "Testing and Launching Applications",
    ],
    userScales: [
        [100, 10000],
        [10000, 100000],
        [100000, 1000000],
        [1000000, "+"]
    ],
    phoneServices: [
        "Location",
        "Contacts",
        "Messages",
        "Bluetooth",
        "NFC",
        "Photos/Camera",
        "Face/Fingerprint Recognition",
        "Microphone",
        "Cloud Storage",
        "Motion Sensors",
    ],
    appPermissions: [
        "ACCESS_LOCATION_EXTRA_COMMANDS",
        "ACCESS_NETWORK_STATE",
        "ACCESS_NOTIFICATION_POLICY",
        "ACCESS_WIFI_STATE",
        "BLUETOOTH",
        "BLUETOOTH_ADMIN",
        "BROADCAST_STICKY",
        "CHANGE_NETWORK_STATE",
        "CHANGE_WIFI_MULTICAST_STATE",
        "CHANGE_WIFI_STATE",
        "DISABLE_KEYGUARD",
        "EXPAND_STATUS_BAR",
        "GET_PACKAGE_SIZE",
        "INSTALL_SHORTCUT",
        "INTERNET",
        "KILL_BACKGROUND_PROCESSES",
        "MODIFY_AUDIO_SETTINGS",
        "NFC",
        "READ_SYNC_SETTINGS",
        "READ_SYNC_STATS",
        "RECEIVE_BOOT_COMPLETED",
        "REORDER_TASKS",
        "REQUEST_IGNORE_BATTERY_OPTIMIZATIONS",
        "REQUEST_INSTALL_PACKAGES",
        "SET_ALARM",
        "SET_TIME_ZONE",
        "SET_WALLPAPER",
        "SET_WALLPAPER_HINTS",
        "TRANSMIT_IR",
        "UNINSTALL_SHORTCUT",
        "USE_FINGERPRINT",
        "VIBRATE",
        "WAKE_LOCK",
        "WRITE_SYNC_SETTINGS",
        "READ_CALENDAR",
        "WRITE_CALENDAR",
        "CAMERA",
        "READ_CONTACTS",
        "WRITE_CONTACTS",
        "GET_ACCOUNTS",
        "ACCESS_FINE_LOCATION",
        "ACCESS_COARSE_LOCATION",
        "RECORD_AUDIO",
        "READ_PHONE_STATE",
        "READ_PHONE_NUMBERS",
        "CALL_PHONE",
        "ANSWER_PHONE_CALLS ",
        "READ_CALL_LOG",
        "WRITE_CALL_LOG",
        "ADD_VOICEMAIL",
        "USE_SIP",
        "PROCESS_OUTGOING_CALLS",
        "BODY_SENSORS",
        "SEND_SMS",
        "RECEIVE_SMS",
        "READ_SMS",
        "RECEIVE_WAP_PUSH",
        "RECEIVE_MMS",
        "READ_EXTERNAL_STORAGE",
        "WRITE_EXTERNAL_STORAGE",
        "ACCESS_MEDIA_LOCATION",
        "ACCEPT_HANDOVER",
        "ACCESS_BACKGROUND_LOCATION",
        "ACTIVITY_RECOGNITION",
    ],
}

export const ranges = [
    [0, 1000,],
    [1000, 5000,],
    [5000, 10000,],
    [10000, 20000,],
    [20000, 50000,],
    [50000, 100000,],
    [100000, 250000,],
    [250000, 500000,],
    [500000, 100000000,],
]

const oom = (val) => {
    if (val < 1e3) return val
    if (val < 1e6) return `${val / 1e3}k`
    if (val < 1e9) return `${val / 1e6}M`
    return `${val / 1e9}B`
}

export const toRangeName = (range) => {
    let rangeName = ""

    if (range[0] === 0) {
        rangeName = `<$${oom(range[1])}`
    } else if (range[1] === 100000000) {
        rangeName = `$${oom(range[0])}+`
    } else {
        rangeName = `$${oom(range[0])}-$${oom(range[1])}`
    }

    return rangeName
}

export const requiredPermissions = (permissionsObject) => {
    let reqPerm = []
    Object.keys(permissionsObject).forEach(
        permissionName => {
            if (permissionsObject[permissionName])
                reqPerm.push(permissionName)
        }
    )
    return reqPerm
}

export const canEstimateAutomatically = (options) => {
    const {
        isNewProject,
        projectType,
    } = options
    if (!isNewProject || projectType === "iOT Device" || projectType === "Testing and Launching Applications") {
        return false
    }

    return true
}

// Returns [low, high] estimate or 'false' if a detailed estimate is needed.
/**
 * @param options
 * @param callback
 */
export function calculateEstimate(options) {
    const {
        projectType, viewsOrPagesRequired, permissionsRequired, loginRequired, userScale
    } = options

    let numPermissions = 1
    Object.keys(permissionsRequired).forEach(
        permissionName => {
            if (permissionsRequired[permissionName])
                numPermissions += 1 
        }
    )

    const toRange = (value,) => {
        return ranges.find(([low, high]) => low < value && value < high) || false
    }

    if (!canEstimateAutomatically(options)) {
        return false
    }
    if (viewsOrPagesRequired < 1)
        return false

    let loginFactor = loginRequired ? estimateFactors.login : 1
    let projectCost = 0.0
    
    if (projectType === "App") {
        projectCost = fib(viewsOrPagesRequired) + fib(numPermissions) * 21            
    }

    if (projectType === "Website") {
        if (userScale === undefined) {
            return false
        }
        projectCost = viewsOrPagesRequired * estimateFactors.webpageCompletionTime * userScale
                    + fib(viewsOrPagesRequired) / 100
    }

    projectCost *= loginFactor
                * estimateFactors.testing 
                * estimateFactors.rate
    return toRange(projectCost)
}
