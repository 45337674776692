/**
 * @file The ConstructionScreen component displayed only during maintenance.
 * @author Andrew Berger
 */

import React from "react"

import {
    Container, Image, 
} from "react-bootstrap"

import DGViewContainer from "./Components/DGViewContainer"

/**
 * @param props
 */
function ConstructionScreen(props) {
    const { navigation } = props
    return (
        <DGViewContainer
            navigation={navigation}
        >
            <p style={{
                fontSize: 32, margin: 8, textAlign: "center" , color: "white", padding: 50
            }} className="text-center">
                Datagee is currently under construction. Thank you for your patience while we create something great!
            </p>
        </DGViewContainer>
    )
}

export default ConstructionScreen
