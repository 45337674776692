import React from "react"
import DGInfo from "../DGInfo"
import DGLink from "../DGLink"

const Consultation = (props) => {
    return (
        <div>
            <DGInfo>
                What to expect:
                <ul>
                    <li>Explain your idea in as much detail as possible</li>
                    <li>Discuss your project for 15-30 minutes</li>
                    <li>Get familiar with our estimate process</li>
                </ul>
            </DGInfo>
            <DGInfo>
                What to bring:
                <ul>
                    <li>Notes about your project idea</li>
                    <li>Wireframes or rough sketches of your idea</li>
                    <li>If a physical device is involved, pictures of the device and a description</li>
                    <li>Your quick estimate from our quick estimate calculator (if applicable)</li>
                </ul>
            </DGInfo>
            <DGInfo>
                <DGLink
                    href="https://www.picktime.com/datagee"
                >
                    Book your free consultation today 
                </DGLink>
                &nbsp;(offer subject to change)
            </DGInfo>
            <br />
            <DGInfo>
                Visit the <DGLink href="/Contact">contact page</DGLink> for details about our location and hours.
            </DGInfo>
            <br />
            <br />
        </div>
    )
}

export default Consultation