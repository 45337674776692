import React from "react"

const PermissionsButton = (props) => {
    const { 
        children, 
        style, 
        selected, 
        onClick,
        selectedTextColor,
        selectedBackgroundColor, 
    } = props
    const DEFAULT_SELECTED_TEXT_COLOR = "white"
    const DEFAULT_SELECTED_BG_COLOR = "#0d6efd"

    let textColorDuringSelection = selectedTextColor || DEFAULT_SELECTED_TEXT_COLOR
    let bgColorDuringSelection = selectedBackgroundColor || DEFAULT_SELECTED_BG_COLOR

    return (
        <>
            <div
                onClick={onClick}
                style={{
                    ...style,
                    margin: "5px 5px 5px 0px",
                    backgroundColor: selected ? bgColorDuringSelection : style.backgroundColor || "initial",
                    color: selected ? textColorDuringSelection : style.color || "initial",
                    borderRadius: 5,
                    border: `1px solid ${style.borderColor || "black"}`,
                    padding: 10,
                    width: "auto",
                    display: "inline-block"
                }}
            >
                {children}
            </div>
        </>
    )
}

export default PermissionsButton