/**
 * @file The NavBar component for each page of Datagee's main website.
 * @author Andrew Berger
 */

import React, { useState } from "react"
import {
    Navbar,
    Nav,
    Image,
} from "react-bootstrap"


import Logo from "../../assets/dgClear.svg"

/**
 * @param props
 */

const DGLogo = (props) => {
    return (
        <div
            style={{
                flexDirection: "row", verticalAlign: "middle" 
            }}
        >
            <Image
                src={Logo}
                style={{
                    width: "45px",
                    height: "45px",
                    // marginTop: "0.8vh",
                }}
            />
            <span
                className="align-middle ms-2"
                style={{
                    color: "#fff",
                    fontSize: "28px",
                    fontFamily: "Helvetica",
                }}
            >
                Datagee
            </span>
            <span
                className="align-top"
                style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontFamily: "Helvetica",
                }}
            >
                &reg;
            </span>
        </div>
    )
}

const DGNavBar = (props) => {
    const lightblue = "rgb(0,200,255)"
    const dgred = "rgb(255, 10, 10)"
    const {
        getPathFromName, 
        headerLinks,
        selectedLink,
        color
    } = props

    const [localSelectedValue, selectLink] = useState(undefined)

    return (
        <Navbar
            style={{
                backgroundColor: color || "rgba(0, 0, 0, 0)",
                border: "none",
                paddingLeft: 12,
                paddingRight: 12,
            }}
            expand="lg"
            variant="dark"
        >
            <Navbar.Brand href="/home">
                <DGLogo />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    { headerLinks.map(([name, display], i) => (
                        <Nav.Link
                            style={{
                                color: (i === selectedLink) ? lightblue : "#fff",
                                // textShadow: (i === selectedLink) ? `0.5px 0.5px ${lightblue}` : "none",
                                cursor: "pointer",
                                fontSize: "20px",
                                textAlign: "center",
                                opacity: i === localSelectedValue ? 0.25 : 1.0
                            }}
                            href={getPathFromName(name)}
                            key={display}
                            onMouseDown={() => { 
                                selectLink(i)
                            }}
                        >
                            { display }
                        </Nav.Link>
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default DGNavBar