import React, { useState, useEffect } from "react"
import DGInfo from "./Components/DGInfo"
import AnnouncementsViewModel from "../ViewModel/AnnouncementsVM"
import DGViewContainer from "./Components/DGViewContainer"
import BackgroundImage from "../assets/background_image.png"
import LoadingIndicator from "./Components/LoadingIndicator"
import DGInfoPage from "./Components/DGInfoPage"
import DGHR from "./Components/DGHR"

let Announcements = (props) => {
    const {
        color,
        navigation,
    } = props

    const [announcements, setAnnouncements] = useState([])
    const [AnnouncementsVM, _] = useState(new AnnouncementsViewModel())

    let loadAnnouncements = () => {
        const page = "homepage"
        AnnouncementsVM && AnnouncementsVM.listAnnouncements(page).then(
            (a) => {
                setAnnouncements(a)
            }
        )
    }

    useEffect(loadAnnouncements, [])

    return (
        <DGViewContainer 
            navigation={navigation}
            backgroundImage={BackgroundImage}
            selectedLink={1}
        >
            <DGInfoPage>
                <DGInfo headingSize="h1">
                    Announcements
                    <DGHR />
                </DGInfo>
                <DGInfo>
                    {announcements.length === 0 && <LoadingIndicator />}
                    {announcements.map(
                        (announcement, i) => {
                            return <span key={announcement}>{announcement}<br />{i < announcements.length - 1 && <DGHR />}</span>
                        }
                    )}
                    <br />
                </DGInfo>
            </DGInfoPage>
        </DGViewContainer>
    )
}

export default Announcements