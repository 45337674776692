import spinner from "../../assets/spinner.gif"
import React from "react"

const LoadingIndicator = (props) => {
    const {
        size,
    } = props
    return (
        <img 
            src={spinner}
            style={{
                width: size ?? 50
            }}
        />
    )
}

export default LoadingIndicator