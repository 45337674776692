/**
 * @file The QuoteContact component displayed on Datagee's site.
 * @author Andrew Berger
 */

import React, {
    useState 
} from "react"

import {
    Form,
    FloatingLabel,
    Button,
} from "react-bootstrap"

/**
 * @param data
 */
export function sendQuoteRequest(data) {
    const {
        description, projectName, name, ...fields 
    } = data
    const recipient = "estimates"

    const body = encodeURIComponent(
        "I would like to request a quote for this project.\n"
        + description + "\n\n"
        + Object.entries(fields).reduce((str, [fname, fval]) => (
            str + `\n${fname}: ${fval}`
        ),
        ""
        )
        + `\n\n${name}`,
    )

    const subject = encodeURIComponent(
        `${projectName} quote request`
    )

    window.open(
        `mailto:${recipient}@datagee.com?subject=${subject}&body=${body}`
    )
}

/**
 * @param props
 */
export default function QuoteContact(props) {
    const {
        estimateData 
    } = props

    const [description, setDescription] = useState("")
    const [projectName, setProjectName] = useState("")
    const [name, setName] = useState("")

    return (
        <Form style={{ paddingRight: 23 }}>
            <Form.Group className="mb-3">
                <FloatingLabel label="Your Name">
                    <Form.Control
                        placeholder="Name"
                        onChange={({
                            target: {
                                value 
                            } 
                        }) => setName(value)}
                    />
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
                <FloatingLabel label="Project Name">
                    <Form.Control
                        placeholder="Project"
                        onChange={({
                            target: {
                                value 
                            } 
                        }) => setProjectName(value)}
                    />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="form-inline">
                <Form.Label>Describe the project</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={2}
                    onChange={({
                        target: {
                            value 
                        } 
                    }) => setDescription(value)}
                />
            </Form.Group>
            <Button
                variant="primary"
                style={{
                    marginTop: 10, 
                    marginBottom: 10, 
                }}
                onClick={
                    () => sendQuoteRequest(
                        {
                            ...estimateData, description, projectName, name 
                        }
                    )
                }
            >
                Submit
            </Button>
        </Form>
    )
}
