import React from "react"

import "bootstrap/dist/css/bootstrap.min.css"

import NavigationRoot from "./Global/Navigation"

const App = () => {
    return (
        <div className="App">
            <NavigationRoot />
        </div>
    )
}

export default App
